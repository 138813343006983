import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/layout/snackbar/snackbar.component';
import { NotificationVariants } from 'src/app/models/utils';

export interface SnackbarData {
  type: NotificationVariants;
  message: string;
  verticalPosition?: 'top' | 'bottom';
  horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right';
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  WORDS_PER_MINUTE = 150;

  determineDuration(content: string, duration?: number): number {
    if (duration) {
      return duration;
    }
    const readingTime =
      (content.split(/\s+/).length / this.WORDS_PER_MINUTE) * 60 * 1000;
    return readingTime < 3500 ? 3500 : readingTime;
  }

  open({
    type,
    message,
    verticalPosition = 'top',
    horizontalPosition = 'end',
    duration,
  }: SnackbarData) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        type,
        message,
        close: this.close.bind(this),
      },
      horizontalPosition,
      verticalPosition,
      duration: this.determineDuration(message, duration),
    });
  }

  close() {
    this._snackBar.dismiss();
  }
}
