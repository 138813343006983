import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { type: string; message: string; close: () => void }
  ) {}

  getClasses() {
    let classes = ['d-flex', 'align-center'];
    switch (this.data.type) {
      case 'success':
        classes.push('bg-success');
        break;
      case 'error':
        classes.push('bg-error');
        break;
      case 'warn':
        classes.push('bg-warn');
        break;
      default:
        classes.push('bg-info');
    }
    return classes.join(' ');
  }

  getIcon() {
    switch (this.data.type) {
      case 'success':
        return 'done';
      case 'error':
        return 'error';
      case 'warn':
        return 'warning';
      default:
        return 'info';
    }
  }
}
